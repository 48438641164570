<template>
  <div id="app">
    <Theme>
      <Page>
        <router-view />
        <Grid class="nav">
          <GridColumn :columns="12">
            <router-link to="/">Home</router-link> |
            <router-link to="/privacy">Privacy Policy</router-link>
          </GridColumn>
        </Grid>
      </Page>
    </Theme>
  </div>
</template>

<script>
import Theme from "@gospore/gospore-web-ui-library-theme";
import Page, { Grid, GridColumn } from "@gospore/gospore-web-ui-library-layout";
import "@gospore/gospore-web-ui-library-theme";

export default {
  name: "App",
  components: {
    Theme,
    Page,
    Grid,
    GridColumn,
  },
};
</script>

<style lang="less" scoped>
.nav {
  padding-top: @size0 !important;

  a.router-link-exact-active {
    font-weight: bold;
  }
}
</style>
