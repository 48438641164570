<template>
  <div class="home">
    <Grid>
      <GridColumn :columns="12">
        <div class="logo-container">
          <img
            class="logo"
            alt="Symbol Harvesting Companion logo"
            src="@/assets/logo.png"
          />
          <p>x</p>
          <img
            class="logo-symbol"
            alt="Symbol blockchain logo"
            src="@/assets/logo-symbol.png"
          />
        </div>
        <div class="title-container">
          <h1 class="title">Symbol Harvesting Companion</h1>
          <Keyword color="teal">iOS App</Keyword>
        </div>
        <h2 class="subtitle heading-400">Track your harvesting revenue.</h2>
        <hr />
        <p>
          The Symbol Harvesting Companion App helps you keep track of your
          harvesting revenue on the Symbol blockchain.
        </p>
        <p>See useful information like:</p>
        <ul>
          <li>The total amount of XYM you've harvested</li>
          <li>How much XYM you've harvested per day/week/month/year</li>
          <li>
            The averages of how much you're harvesting per day/week/month/year
          </li>
          <li>
            Harvesting entries contain useful information like timestamp, block
            height, etc.
          </li>
          <li>Toggle between XYM and USD currency display modes</li>
          <li>
            Chart view to compare harvesting revenues in different timeframes
          </li>
        </ul>
        <p>
          The app is currently available to all delegated harvesters on the
          Symbol Harvesting Companion node. If you're not yet harvesting on the
          Symbol blockchain the app will walk you through the activation
          process.
        </p>
        <p>
          Please let me know if you've got any suggestions for improvements and
          new features!
        </p>
        <hr />
      </GridColumn>
      <GridColumn :columns="12">
        <div class="gallery">
          <slider ref="slider" :options="options">
            <slideritem
              class="slideritem"
              v-for="screenshot in screenshots"
              :key="screenshot.name"
            >
              <img :alt="screenshot.alt" :src="getImgUrl(screenshot.name)" />
            </slideritem>
          </slider>
        </div>
      </GridColumn>
      <GridColumn :columns="12">
        <hr />
        <div class="social">
          <h3 class="heading-500">Contact</h3>
          <p>
            Twitter:
            <a target="_blank" href="https://twitter.com/thomasoehri">
              @thomasoehri
            </a>
            <br />
            Email:
            <a href="mailto:symbol-harvesting-companion@nym.hush.com">
              symbol-harvesting-companion@nym.hush.com
            </a>
          </p>
        </div>
      </GridColumn>
    </Grid>
  </div>
</template>

<script>
import { Grid, GridColumn } from "@gospore/gospore-web-ui-library-layout";
import Keyword from "@gospore/gospore-web-ui-library-keyword";
import { slider, slideritem } from "vue-concise-slider";

export default {
  name: "Home",
  components: {
    Grid,
    GridColumn,
    Keyword,
    slider,
    slideritem,
  },
  data() {
    return {
      screenshots: [
        {
          name: "Screenshot-Harvesting",
          alt: "Screenshot of the app showing an overview of the harvesting earnings.",
        },
        {
          name: "Screenshot-Chart-Day",
          alt: "Screenshot of the app showing the daily earnings chart.",
        },
        {
          name: "Screenshot-Blocks",
          alt: "Screenshot of the app showing a list of harvested blocks.",
        },
        {
          name: "Screenshot-Block-Detail",
          alt: "Screenshot of the app showing a harvested block in detail.",
        },
        {
          name: "Screenshot-Chart-Monthly",
          alt: "Screenshot of the app showing the monthly earnings chart.",
        },
        {
          name: "Screenshot-Harvesting-Chart",
          alt: "Screenshot of the app showing an overview of the harvesting earnings.",
        },
      ],
      options: {
        loop: true,
        currentPage: 0,
        slidesToScroll: 1,
      },
    };
  },
  methods: {
    handleKeydown(e) {
      switch (e.keyCode) {
        case 37:
          this.slidePrevious();
          break;
        case 39:
          this.slideNext();
          break;
      }
    },
    slideNext() {
      this.$refs.slider.$emit("slideNext");
    },
    slidePrevious() {
      this.$refs.slider.$emit("slidePre");
    },
    getImgUrl(img) {
      var images = require.context("@/assets/", false, /\.png$/);
      return images("./" + img + ".png");
    },
  },
  beforeMount() {
    window.addEventListener("keydown", this.handleKeydown, null);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>

<style lang="less" scoped>
.logo-container {
  display: flex;
  align-items: center;

  p {
    margin-left: @size12;
    margin-right: @size24;
    margin-top: -@size4;
    font-size: 20px;
    color: #heading-color.subtle[light];

    [dark-mode="true"] & {
      color: #heading-color.subtle[dark];
    }
  }
}
.logo {
  height: @size96;
  width: @size96;
  margin-left: -@size8;
}
.logo-symbol {
  height: 70px;
}
.title-container {
  display: flex;
  align-items: center;
  margin-top: @size16;
}
.title {
  margin-right: @size16;
}
.subtitle {
  margin-top: @size8 !important;
  margin-bottom: @size48 !important;
  color: #heading-color.subtle[light] !important;

  [dark-mode="true"] & {
    color: #heading-color.subtle[dark] !important;
  }
}
.slideritem {
  width: 31.9%;
  margin-right: 2%;
}
@media only screen and (max-width: 980px) {
  .slideritem {
    width: 48.8%;
    margin-right: 2%;
  }
}
@media only screen and (max-width: 560px) {
  .slideritem {
    width: 100%;
  }
}
.slideritem img {
  max-width: 100%;
  border-radius: @size8;
}
.social {
  display: inline-block;
  padding: @size12 @size16;
  border-radius: @size8;
  background: @code-block-color[light];

  p:first-of-type {
    margin-top: @size8;
  }

  [dark-mode="true"] & {
    background: @code-block-color[dark];
  }
}
</style>
