import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      scrollToTop: true,
    },
  },
  {
    path: "/privacy",
    name: "PrivacyPolicy",
    component: () =>
      import(/* webpackChunkName: "privacy" */ "@/views/PrivacyPolicyView.vue"),
    meta: {
      scrollToTop: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return new Promise((resolve) => {
      const position = {};

      // check if any matched route config has meta that requires scrolling to top
      if (to.matched.some((m) => m.meta.scrollToTop)) {
        position.x = 0;
        position.y = 0;
      }

      resolve(position);
    });
  },
  base: process.env.BASE_URL,
  routes,
});

export default router;
